import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext, useAuth } from "../../firebase";
import { useMicroCopyFi } from "../../hooks/microCopy";
import GetMicroCopy from "../../shared/getMicroCopy";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import * as styles from "./form.module.scss";

const GetCodeForm = ({ location }) => {
  const microCopyTexts = useMicroCopyFi
  const labelCode = GetMicroCopy(microCopyTexts, "lomakeKoodi")
  const labelEmail = GetMicroCopy(microCopyTexts, "lomakeSähköposti")
  const textCode = GetMicroCopy(microCopyTexts, 'getCodeForm')
  const textCodeContact = GetMicroCopy(microCopyTexts, 'getCodeFormContact')
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");

  const [firstName, setFirstName] = useState("");
  const [phase, setPhase] = useState(1);

  const { user, loading } = useAuth();
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    async function getUser() {
      const response = await firebase.getUser({ email: user.email });
      setFirstName(response.data ? response.data.first_name : "");
    }
    if (firebase && user) {
      getUser();
    }
  }, [loading, user, firebase]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
  
    if (email === "") {
      setError("Sähköpostiosoitte puuttuu");
      return;
    }
  
    if (phase === 1) {
      try {
        setLoadingMessage("Haetaan koodia...")
        const response = await firebase.getGestData({email: email});
        const emailExists = response.data.emailExists;
        if (emailExists) {
          const result = await firebase.reserveUserCode({ email: email });
          if (result && result.data && result.data.code) {
            const userCode = result.data.code;
            setCode(userCode);
            setPhase(2);
            setError("");
            setLoadingMessage("")
          } else {
            setLoadingMessage("")
            setError("Sähköpostiosoitteelle ei löytynyt koodia. Tarkistathan osoitteen.");
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        } else {
          setLoadingMessage("")
          setError("Sähköpostiosoitteelle ei löytynyt koodia. Tarkistathan osoitteen.");
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      } catch (error) {
        console.log(error);
        setLoadingMessage("")
        setError("Sähköpostiosoitteelle ei löytynyt koodia. Tarkistathan osoitteen.");
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    } else if (phase === 2) {
      navigator.clipboard.writeText(code);
      setPhase(3);
    } else if (phase === 3) {
      navigate("/code");
    }
  };
  
  
  
  return (
    <Box
      component="section"
      className={styles.form}
      style={{ padding: "0 20px", maxWidth: "1240px", margin: "85px auto" }}
    >
      <form onSubmit={handleButtonClick}>
        <div className={styles.formRow}>
          <h1>
            Hei {firstName}, lunasta alta pääsykoodisi sovellukseen ja virtuaalisille <br /> Asuntomessuille!
          </h1>
        </div>
        <div className={styles.centerDiv}>
          <p className={styles.pCenter}>
            {textCode}
            <br /> <br />
            {textCodeContact}
          </p>
        </div>
        <div className={styles.inputContainer}>
        <label htmlFor="email">
          {phase === 1 ? labelEmail : labelCode} <span>*</span>
        </label>
        <input
          id="email"
          value={phase === 1 ? email : code}
          name="email"
          type="text"
          required
          readOnly={phase === 2}
          onChange={handleInputChange}
        />
        {loadingMessage && <div className={styles.formRow3}>{loadingMessage}</div>}

        {error && <div className={styles.formRow2}>{error}</div>}
        </div>
        <button type="submit">
          {phase === 1 ? 'Näytä Koodi' : (phase === 2 ? "Kopioi" : "Siirry kirjautumiseen")}
        </button>
      </form>
    </Box>
  );
};

export default GetCodeForm;
